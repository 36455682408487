import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mb-2"
}
const _hoisted_2 = { class: "flex py-4 px-6" }
const _hoisted_3 = { class: "flex py-4 px-6" }
const _hoisted_4 = { class: "flex justify-end" }
const _hoisted_5 = { class: "py-4 px-6" }

import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { ColumnDefinition } from '@/components/_global/BalTable/BalTable.vue';
import ClaimProtocolRewardsBtn from '@/components/btns/ClaimProtocolRewardsBtn.vue';
import useBreakpoints from '@/composables/useBreakpoints';
import useNumbers, { FNumFormats } from '@/composables/useNumbers';
import { bnum } from '@/lib/utils';
import { TokenInfo } from '@/types/TokenList';

/**
 * TYPES
 */
export type ProtocolRewardRow = {
  token: TokenInfo;
  amount: string;
  value: string;
  deprecated?: boolean;
};

type Props = {
  rewardsData: ProtocolRewardRow[];
  isLoading: boolean;
  deprecated: false;
};

/**
 * PROPS & EMITS
 */

export default _defineComponent({
  props: {
    rewardsData: { type: Array, required: true },
    isLoading: { type: Boolean, required: true },
    deprecated: { type: Boolean, required: true }
  } as unknown as undefined,
  setup(__props: {
  rewardsData: ProtocolRewardRow[];
  isLoading: boolean;
  deprecated: false;
}) {

const props = __props


/**
 * COMPOSABLES
 */
const { t } = useI18n();
const { upToLargeBreakpoint } = useBreakpoints();
const { fNum2 } = useNumbers();

/**
 * STATE
 */
const columns = ref<ColumnDefinition<ProtocolRewardRow>[]>([
  {
    name: t('token'),
    id: 'token',
    accessor: 'token',
    Cell: 'tokenColumnCell',
    align: 'left',
    width: 125,
    noGrow: true,
  },
  {
    name: '',
    id: 'symbol',
    accessor: 'symbol',
    Cell: 'symbolColumnCell',
    width: 350,
  },
  {
    name: t('amount'),
    id: 'amount',
    align: 'right',
    width: 150,
    accessor: ({ amount }) => `${fNum2(amount, FNumFormats.token)}`,
  },
  {
    name: t('value'),
    id: 'value',
    align: 'right',
    width: 150,
    totalsCell: 'totalValueCell',
    accessor: ({ value }) => fNum2(value, FNumFormats.fiat),
  },
  {
    name: '',
    id: 'claim',
    accessor: 'claim',
    Cell: 'claimColumnCell',
    totalsCell: 'claimTotalCell',
    width: 150,
  },
]);

/**
 * COMPUTED
 */
const totalClaimAmount = computed((): string =>
  props.rewardsData
    .reduce((acc, row) => acc.plus(row.amount), bnum('0'))
    .toString()
);

const totalClaimValue = computed((): string =>
  props.rewardsData
    .reduce((acc, row) => acc.plus(row.value), bnum('0'))
    .toString()
);

const hasClaimableBalance = computed((): boolean => {
  if (props.isLoading) return true;
  return bnum(totalClaimAmount.value).gt(0);
});

return (_ctx: any,_cache: any) => {
  const _component_BalAsset = _resolveComponent("BalAsset")!
  const _component_BalTable = _resolveComponent("BalTable")!
  const _component_BalCard = _resolveComponent("BalCard")!

  return (_openBlock(), _createElementBlock("div", null, [
    (__props.deprecated && _unref(hasClaimableBalance))
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("h6", null, _toDisplayString(_ctx.$t('pastEarnings.title')), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('pastEarnings.description')), 1)
        ]))
      : _createCommentVNode("", true),
    (_unref(hasClaimableBalance) || !__props.deprecated)
      ? (_openBlock(), _createBlock(_component_BalCard, {
          key: 1,
          shadow: "lg",
          square: _unref(upToLargeBreakpoint),
          noBorder: _unref(upToLargeBreakpoint),
          noPad: "",
          class: "mb-8"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_BalTable, {
              columns: columns.value,
              data: __props.rewardsData,
              isLoading: __props.isLoading,
              skeletonClass: "h-64",
              square: _unref(upToLargeBreakpoint)
            }, {
              tokenColumnCell: _withCtx(({ token }) => [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_BalAsset, {
                    address: token.address
                  }, null, 8, ["address"])
                ])
              ]),
              symbolColumnCell: _withCtx(({ token }) => [
                _createElementVNode("div", _hoisted_3, _toDisplayString(token.symbol), 1)
              ]),
              totalValueCell: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, _toDisplayString(_unref(fNum2)(_unref(totalClaimValue), _unref(FNumFormats).fiat)), 1)
              ]),
              claimColumnCell: _withCtx(({ token, amount, value }) => [
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(ClaimProtocolRewardsBtn, {
                    tokenAddress: token.address,
                    fiatValue: value,
                    disabled: _unref(bnum)(amount).eq(0),
                    deprecated: __props.deprecated
                  }, null, 8, ["tokenAddress", "fiatValue", "disabled", "deprecated"])
                ])
              ]),
              claimTotalCell: _withCtx(() => [
                _createVNode(ClaimProtocolRewardsBtn, {
                  fiatValue: _unref(totalClaimValue),
                  disabled: !_unref(hasClaimableBalance),
                  deprecated: __props.deprecated
                }, null, 8, ["fiatValue", "disabled", "deprecated"])
              ]),
              _: 1
            }, 8, ["columns", "data", "isLoading", "square"])
          ]),
          _: 1
        }, 8, ["square", "noBorder"]))
      : _createCommentVNode("", true)
  ]))
}
}

})