import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { useI18n } from 'vue-i18n';

import useProtocolRewardsQuery from '@/composables/queries/useProtocolRewardsQuery';
import useNumbers, { FNumFormats } from '@/composables/useNumbers';
import { FeeDistributor } from '@/services/balancer/contracts/contracts/fee-distributor';
import { configService } from '@/services/config/config.service';
import useWeb3 from '@/services/web3/useWeb3';

import TxActionBtn from './TxActionBtn/TxActionBtn.vue';

/**
 * TYPES
 */
type Props = {
  tokenAddress?: string;
  fiatValue: string;
  deprecated?: boolean;
};

/**
 * PROPS & EMITS
 */

export default _defineComponent({
  props: {
    tokenAddress: { type: String, required: false },
    fiatValue: { type: String, required: true },
    deprecated: { type: Boolean, required: false }
  } as unknown as undefined,
  setup(__props: {
  tokenAddress?: string;
  fiatValue: string;
  deprecated?: boolean;
}) {

const props = __props


/**
 * SERVICES
 */
const feeDistributorV1 = new FeeDistributor(
  configService.network.addresses.feeDistributorDeprecated
);
const feeDistributorV2 = new FeeDistributor(
  configService.network.addresses.feeDistributor
);

/**
 * COMPOSABLES
 */
const { t } = useI18n();
const { fNum2 } = useNumbers();
const { account } = useWeb3();
const protocolRewardsQuery = useProtocolRewardsQuery();

/**
 * METHODS
 */
function claimTx() {
  const feeDistributor = props.deprecated ? feeDistributorV1 : feeDistributorV2;

  if (props.tokenAddress)
    return feeDistributor.claimBalance(account.value, props.tokenAddress);
  return feeDistributor.claimBalances(account.value);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(TxActionBtn, _mergeProps({
    label: __props.tokenAddress ? _ctx.$t('claim') : _ctx.$t('claimAll'),
    color: "gradient",
    size: "sm",
    actionFn: claimTx,
    onConfirmFn: _unref(protocolRewardsQuery).refetch.value,
    action: "claim",
    summary: `${_unref(t)('claim')} ${_unref(fNum2)(__props.fiatValue, _unref(FNumFormats).fiat)}`,
    confirmingLabel: _ctx.$t('claiming')
  }, _ctx.$attrs), null, 16, ["label", "onConfirmFn", "summary", "confirmingLabel"]))
}
}

})