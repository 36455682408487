import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { getAddress } from '@ethersproject/address';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import TxActionBtn from '@/components/btns/TxActionBtn/TxActionBtn.vue';
import useGaugesDecorationQuery from '@/composables/queries/useGaugesDecorationQuery';
import useGaugesQuery from '@/composables/queries/useGaugesQuery';
import useNumbers, { FNumFormats } from '@/composables/useNumbers';
import { balancerMinter } from '@/services/balancer/contracts/contracts/balancer-minter';
import { Gauge } from '@/services/balancer/gauges/types';

/**
 * TYPES
 */
type Props = {
  gauges: Gauge[];
  label: string;
  amount: string;
};

/**
 * PROPS & EMITS
 */

export default _defineComponent({
  props: {
    gauges: { type: Array, required: true },
    label: { type: String, required: true },
    amount: { type: String, required: true }
  } as unknown as undefined,
  setup(__props: {
  gauges: Gauge[];
  label: string;
  amount: string;
}) {

const props = __props


/**
 * COMPOSABLES
 */
const { t } = useI18n();
const { fNum2 } = useNumbers();
const { data: subgraphGauges } = useGaugesQuery();
const gaugesQuery = useGaugesDecorationQuery(subgraphGauges);

/**
 * COMPUTED
 */
const gaugeAddresses = computed((): string[] =>
  props.gauges.map(gauge => getAddress(gauge.id))
);

/**
 * METHODS
 */
async function claimTx() {
  if (props.gauges.length === 1) {
    return await balancerMinter.mint(gaugeAddresses.value[0]);
  } else {
    return await balancerMinter.mintMany(gaugeAddresses.value);
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(TxActionBtn, {
    label: __props.label,
    color: "gradient",
    size: "sm",
    actionFn: claimTx,
    onConfirmFn: _unref(gaugesQuery).refetch.value,
    action: "claim",
    summary: `${_unref(t)('claim')} ${_unref(fNum2)(props.amount, _unref(FNumFormats).token)} BAL`,
    confirmingLabel: _ctx.$t('claiming')
  }, null, 8, ["label", "onConfirmFn", "summary", "confirmingLabel"]))
}
}

})