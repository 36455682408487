import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { getAddress } from '@ethersproject/address';
import { useI18n } from 'vue-i18n';

import useGaugesDecorationQuery from '@/composables/queries/useGaugesDecorationQuery';
import useGaugesQuery from '@/composables/queries/useGaugesQuery';
import useNumbers, { FNumFormats } from '@/composables/useNumbers';
import { LiquidityGauge } from '@/services/balancer/contracts/contracts/liquidity-gauge';
import { Gauge } from '@/services/balancer/gauges/types';

import TxActionBtn from '../TxActionBtn/TxActionBtn.vue';

/**
 * TYPES
 */
type Props = {
  gauge: Gauge;
  fiatValue: string;
};

/**
 * PROPS & EMITS
 */

export default _defineComponent({
  props: {
    gauge: { type: null, required: true },
    fiatValue: { type: String, required: true }
  } as unknown as undefined,
  setup(__props: {
  gauge: Gauge;
  fiatValue: string;
}) {

const props = __props


/**
 * COMPOSABLES
 */
const { t } = useI18n();
const { fNum2 } = useNumbers();
const { data: subgraphGauges } = useGaugesQuery();
const gaugesQuery = useGaugesDecorationQuery(subgraphGauges);

/**
 * STATE
 */
const gaugeAddress = getAddress(props.gauge.id);
const liquidityGaugeContract = new LiquidityGauge(gaugeAddress);

/**
 * METHODS
 */
function claimTx() {
  return liquidityGaugeContract.claimRewards();
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(TxActionBtn, {
    label: _unref(t)('claimAll'),
    color: "gradient",
    size: "sm",
    actionFn: claimTx,
    onConfirmFn: _unref(gaugesQuery).refetch.value,
    action: "claim",
    summary: `${_unref(t)('claim')} ${_unref(fNum2)(props.fiatValue, _unref(FNumFormats).fiat)}`,
    confirmingLabel: _unref(t)('claiming')
  }, null, 8, ["label", "onConfirmFn", "summary", "confirmingLabel"]))
}
}

})