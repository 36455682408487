import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "py-4 px-6" }
const _hoisted_2 = { class: "py-4 px-6" }
const _hoisted_3 = { class: "py-4 px-6" }
const _hoisted_4 = { class: "flex justify-end" }
const _hoisted_5 = { class: "flex justify-end" }

import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

import { ColumnDefinition } from '@/components/_global/BalTable/BalTable.vue';
import ClaimBalBtn from '@/components/btns/ClaimBalBtn/ClaimBalBtn.vue';
import useBreakpoints from '@/composables/useBreakpoints';
import useNumbers, { FNumFormats } from '@/composables/useNumbers';
import {
  isStableLike,
  orderedPoolTokens,
  orderedTokenAddresses,
} from '@/composables/usePool';
import { bnum } from '@/lib/utils';
import { GaugePool } from '@/pages/claim.vue';
import { Gauge } from '@/services/balancer/gauges/types';

/**
 * TYPES
 */
export type RewardRow = {
  gauge: Gauge;
  pool: GaugePool;
  amount: string;
  value: string;
};

type Props = {
  rewardsData: RewardRow[];
  isLoading: boolean;
};

/**
 * PROPS & EMITS
 */

export default _defineComponent({
  props: {
    rewardsData: { type: Array, required: true },
    isLoading: { type: Boolean, required: true }
  } as unknown as undefined,
  setup(__props: {
  rewardsData: RewardRow[];
  isLoading: boolean;
}) {

const props = __props


/**
 * COMPOSABLES
 */
const { t } = useI18n();
const { upToLargeBreakpoint } = useBreakpoints();
const { fNum2 } = useNumbers();
const router = useRouter();

/**
 * STATE
 */
const columns = ref<ColumnDefinition<RewardRow>[]>([
  {
    name: t('pools'),
    id: 'icons',
    accessor: 'icons',
    Cell: 'iconsColumnCell',
    width: 125,
    noGrow: true,
  },
  {
    name: '',
    id: 'pills',
    accessor: 'pills',
    Cell: 'pillsColumnCell',
    width: 350,
  },
  {
    name: t('amount'),
    id: 'amount',
    align: 'right',
    width: 150,
    totalsCell: 'totalAmountCell',
    accessor: ({ amount }) => `${fNum2(amount, FNumFormats.token)} BAL`,
  },
  {
    name: t('value'),
    id: 'value',
    align: 'right',
    width: 150,
    totalsCell: 'totalValueCell',
    accessor: ({ value }) => fNum2(value, FNumFormats.fiat),
  },
  {
    name: '',
    id: 'claim',
    accessor: 'claim',
    Cell: 'claimColumnCell',
    totalsCell: 'claimTotalCell',
    width: 150,
  },
]);

/**
 * COMPUTED
 */
const allGauges = computed((): Gauge[] =>
  props.rewardsData.map(row => row.gauge)
);

const totalClaimAmount = computed((): string =>
  props.rewardsData
    .reduce((acc, row) => acc.plus(row.amount), bnum('0'))
    .toString()
);

const totalClaimValue = computed((): string =>
  props.rewardsData
    .reduce((acc, row) => acc.plus(row.value), bnum('0'))
    .toString()
);

/**
 * METHODS
 */
function redirectToPool({ pool }: { pool: GaugePool }) {
  router.push({ name: 'pool', params: { id: pool.id } });
}

return (_ctx: any,_cache: any) => {
  const _component_BalAssetSet = _resolveComponent("BalAssetSet")!
  const _component_TokenPills = _resolveComponent("TokenPills")!
  const _component_BalTable = _resolveComponent("BalTable")!
  const _component_BalCard = _resolveComponent("BalCard")!

  return (_openBlock(), _createBlock(_component_BalCard, {
    shadow: "lg",
    square: _unref(upToLargeBreakpoint),
    noBorder: _unref(upToLargeBreakpoint),
    noPad: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_BalTable, {
        columns: columns.value,
        data: __props.rewardsData,
        isLoading: __props.isLoading,
        onRowClick: redirectToPool,
        skeletonClass: "h-64",
        square: _unref(upToLargeBreakpoint)
      }, {
        iconsColumnCell: _withCtx(({ pool }) => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_BalAssetSet, {
              addresses: _unref(orderedTokenAddresses)(pool),
              width: 100
            }, null, 8, ["addresses"])
          ])
        ]),
        pillsColumnCell: _withCtx(({ pool }) => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_TokenPills, {
              tokens: 
              _unref(orderedPoolTokens)(pool.poolType, pool.address, pool.tokens)
            ,
              isStablePool: _unref(isStableLike)(pool.poolType)
            }, null, 8, ["tokens", "isStablePool"])
          ])
        ]),
        claimColumnCell: _withCtx(({ gauge, amount }) => [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(ClaimBalBtn, {
              label: _ctx.$t('claim'),
              gauges: [gauge],
              amount: amount
            }, null, 8, ["label", "gauges", "amount"])
          ])
        ]),
        totalAmountCell: _withCtx(() => [
          _createElementVNode("div", _hoisted_4, _toDisplayString(_unref(fNum2)(_unref(totalClaimAmount), _unref(FNumFormats).token)) + " BAL ", 1)
        ]),
        totalValueCell: _withCtx(() => [
          _createElementVNode("div", _hoisted_5, _toDisplayString(_unref(fNum2)(_unref(totalClaimValue), _unref(FNumFormats).fiat)), 1)
        ]),
        claimTotalCell: _withCtx(() => [
          _createVNode(ClaimBalBtn, {
            label: _ctx.$t('claimAll'),
            gauges: _unref(allGauges),
            amount: _unref(totalClaimAmount)
          }, null, 8, ["label", "gauges", "amount"])
        ]),
        _: 1
      }, 8, ["columns", "data", "isLoading", "square"])
    ]),
    _: 1
  }, 8, ["square", "noBorder"]))
}
}

})